/** Tab Navigation **/
ul.nav {
  width: 100%;
  max-width: 600px; /* Sets a max width to ensure it's centered on larger screens */
  margin: 0 auto 2rem; /* Centers horizontally */
  display: flex;
  align-items: center;
  justify-content: space-around; /* Space items evenly for better centering */
  border: 1px solid #bee3f8;
  border-radius: 2rem;
  padding: 0; /* Ensure no extra padding from `ul` */
}

@media (max-width: 768px) {
  ul.nav {
      width: 90%; /* More flexible width on smaller screens */
  }
}

ul.nav li {
  flex: 1; /* Allow each tab to take equal space */
  padding: 1rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s; /* Shorter transition for responsiveness */
}

ul.nav li:first-child {
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
}

ul.nav li:last-child {
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
}

ul.nav li:hover {
  background: rgba(50, 224, 196, 0.15);
}

ul.nav li.active {
  background: #bee3f8;
}

