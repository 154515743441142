.frame {
    /* overflow-y: auto; */
    /* overflow-x: auto; */
    /* border: 1px solid black;
    height: 3em;
    width: 10em;
    line-height: 1em; */
    white-space: nowrap;
}

.frame::-webkit-scrollbar {
    -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
    width: 0;
}

.frame::-webkit-scrollbar:horizontal {
    height: 11px;
}

.frame::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
}