@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 980px; Add this line to have horizontal scroll bar appears 
} */


/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* Light Theme (Default) */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff; /* Light background */
  color: #000000; /* Dark text */
}

/* Dark Theme */
.dark body {
  background-color: #121212; /* Dark background */
  color: #ffffff; /* Light text */
}

/* Example for a sticky header */
.header {
  position: sticky;
  top: env(safe-area-inset-top, 20px);
  padding: 0 10px;
  background-color: #ffffff;
  color: white;
}

.dark .header {
  background-color: #333333; /* Example: Dark mode header */
  color: #ffffff;
}

/* Footer fixed to the bottom with safe area respect */
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-bottom: env(safe-area-inset-bottom, 20px);
  background-color: #000000;
  color: #ffffff;
}

.dark .footer {
  background-color: #222222; /* Example: Dark mode footer */
  color: #ffffff;
}

/* Cards */
.card {
  background-color: #ffffff; /* Light mode */
  color: #000000;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 16px;
}

.dark .card {
  background-color: #333333; /* Dark mode */
  color: #ffffff;
  border: 1px solid #444444;
}

/* Modals */
.modal {
  background-color: #ffffff; /* Light mode */
  color: #000000;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dark .modal {
  background-color: #222222; /* Dark mode */
  color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #ffffff; /* Light mode */
  color: #000000;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Buttons */
.button {
  padding: 10px 20px;
  background-color: #007bff; /* Light mode */
  color: #ffffff;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #0056b3; /* Hover effect */
}

.dark .button {
  background-color: #1a73e8; /* Dark mode */
  color: #ffffff;
}

.dark .button:hover {
  background-color: #0c47a1;
}

/* Media container */
.media-container {
  background-color: #f0f0f0; /* Light mode placeholder */
  border-radius: 8px;
  overflow: hidden;
}

.dark .media-container {
  background-color: #222222; /* Dark mode placeholder */
}

/* Ensure text contrast for specific content */
.text-contrast {
  color: #000000; /* Light mode */
}

.dark .text-contrast {
  color: #ffffff; /* Dark mode */
}

/* Additional dark mode support for forms */
input, textarea, select {
  background-color: #ffffff; /* Light mode */
  color: #000000;
  border: 1px solid #e5e5e5;
}

.dark input, .dark textarea, .dark select {
  background-color: #333333; /* Dark mode */
  color: #ffffff;
  border: 1px solid #444444;
}

/* Modal close button */
.modal-close {
  background-color: transparent;
  color: #000000; /* Light mode */
  font-size: 1.25rem;
}

.dark .modal-close {
  color: #ffffff; /* Dark mode */
}






/* this part working on fixing viewport to accomodate Apple notch area */
/* Ensure the root HTML element adjusts to safe areas */
html {
  min-height: 100%;
  padding-top: env(safe-area-inset-top, 20px);
  padding-right: env(safe-area-inset-right, 0);
  padding-bottom: env(safe-area-inset-bottom, 20px);
  padding-left: env(safe-area-inset-left, 0);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.dark .html {
  background-color: #222222; /* Example: Dark mode footer */
  color: #fff;
}


/* Sidebar accommodating safe areas */
.left-sidebar {
  padding: env(safe-area-inset-top, 20px) 0 0 env(safe-area-inset-left, 0);
}

.dark .left-sidebar {
  background-color: #222222; /* Example: Dark mode footer */
  color: #fff;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dark .code {
  background-color: #222222; /* Example: Dark mode footer */
  color: #fff;
}

/** this is custom top Hamburger menu to be just below the heading **/
.hamburgerCustomTop {
  top: 2px;
  margin-left: env(safe-area-inset-left, 0);
}

.dark .hamburgerCustomTop {
  background-color: #222222; /* Example: Dark mode footer */
  color: #ffffff;
}

/** this is custom height Hamburger menu to be just below the heading **/
.hamburgerCustomHeight {
  height: 99%;
}

.dark .hamburgerCustomHeight {
  background-color: #222222; /* Example: Dark mode footer */
  color: #ffffff;
}

.text-xxs {
  font-size: 0.5rem; /* Adjust the size as needed */
}

.dark .text-xxs {
  background-color: #222222; /* Example: Dark mode footer */
  color: #222222;
}

.response-error-message {
  text-align: left;
  color: red; 
  font-size: 0.875rem;
}

.response-info-message {
  text-align: left;
  color: green; 
  font-size: 0.875rem;
}

.pointer-events-none {
  pointer-events: none;
}

.dark .pointer-events-none {
  background-color: #222; /* Example: Dark mode footer */
  color: #fff;
}

.opacity-50 {
  opacity: 0.5;
}
/* Media container with fixed aspect ratio */
.media-container {
  position: relative; /* Required for aspect-ratio trick */
  width: 100%; /* Responsive width */
  padding-top: 60%; /** 56.25%; Aspect ratio: 16:9 (height is 56.25% of width) */
  overflow: hidden; /* Ensures media fits within the box */
  background-color: #f0f0f0; /* Optional: placeholder color */
  max-width: 100%;
  max-height: 100%;
  height: inherit !important;
}

.dark .media-container {
  background-color: #222; /* Example: Dark mode footer */
  color: #fff;
}

/* Image/Video styles */
.media-container img, .media-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Fill the container's width */
  /** height: 100%; Fill the container's height */ 
  object-fit: cover; /* Ensure media covers the entire container */
  object-position: center; /* Center the media */
}

.dark .media-container img, .media-container video {
  background-color: #222; /* Example: Dark mode footer */
  color: #fff;
}


/** Down arrow animated */
.down-arrow {
  position: absolute;
  top: calc(90vh - 150px);
  left: calc(50% - 14px);
  width: 10;
  height: 30px;
  border: 2px solid;
  border-radius: 2px;
  animation: jumpInfinite 1.5s infinite;
  color: #bfdbfe;
}

.down-arrow:after {
  content: " ";
  position: absolute;
  top: 18px;
  left: -13px;
  width: 26px;
  height: 26px;
  border-bottom: 4px solid;
  border-right: 4px solid;
  border-radius: 4px;
  transform: rotateZ(45deg);
}

@keyframes jumpInfinite {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 20px;
  }
  100% {
    margin-top: 0;
  }
}







